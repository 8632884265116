@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    background-color: #D8D8D8;
    color: #333 !important;
}

h1 {
    font-size: 3.5rem;
    margin:0;
    padding:0 0 2rem 0;
    line-height:3.2rem;
}

h2 {
    font-size: 1.5rem;
    margin:0;
    padding:0 0 2rem 0;
    line-height:1.2rem;
}

.btn {
    @apply rounded-full;
    @apply text-white;
    @apply px-4;
    @apply py-2;
    
    @apply border;
    @apply border-white;
    @apply cursor-pointer;

    &:disabled, &.disabled {
        @apply bg-gray-400;
        @apply cursor-not-allowed;
        @apply opacity-60;
        @apply hover:bg-gray-400;
    }

    &-primary {
        @extend .btn;
        @apply bg-green-500;
        @apply hover:bg-green-400;
    }

    &-secondary {
        @extend .btn;
        @apply bg-red-500;
        @apply hover:bg-red-400;
    }
}

.link {
    @apply underline;
    @apply text-green-500;
    @apply cursor-pointer;
    @apply opacity-100;

    &:hover {
        @apply text-green-400;
    }

    &-dark{
        @extend .link;
        @apply text-green-700;

        &:hover {
            @apply text-green-600;
        }

    }
}

*:focus {
    outline: none !important;
}

.MuiTableContainer-root {
    background: white;
    border-radius: 20px;
    -webkit-box-shadow: 0px 4px 3px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 4px 3px 0px rgba(0,0,0,0.1);
}

.MuiTable-root {
    
}

tbody .MuiTableRow-root {
    cursor: pointer;

    &:hover {
        background: #fbfbfb;
    }
}

/* Material UI table */
.MuiTableCell-head {
    border-bottom: 1px solid #eaeaea !important;
    
}

.MuiTableCell-head,
.MuiTableCell-head > * {
    font-weight: 600 !important;
    font-size: 1.25rem;
    color: #333 !important;
}

.MuiTableCell-body,
.MuiTableCell-body > * {
    color: #333 !important;
}

.MuiTableHead-root.resizable .MuiTableRow-root .MuiTableCell-head {
    // resize: horizontal;
    // overflow-x: overlay;
    // overflow-y: hidden;
}

input:disabled {
    background-color:#eaeaea;
}